// Here you can add other styles
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.infoBox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.testList {
  // background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-height: 80vh;
}

.testList:hover {
  overflow-y: auto;
  overflow-y: overlay;
}

.loader-container {
  position: fixed !important;
  z-index: 1090;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
}

.page-loader {
  width: 4rem;
  height: 4rem;
}

.webcamCapture {
  position: relative;
  width: 100%;
}
@media only screen and (max-device-width: 640px) {
  .webcamCapture {
    position: fixed;
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-device-width: 768px) {
  .webcamCapture {
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .webcamCapture {
    position: inherit;
    height: 70%;
    width: 100%;
  }
}

.c-app{
  background-color: none;
}