// Variable overrides
// Variable overrides
$primary-base: #f27638;
$primary: #1969e9;
// $primary: #ef3775; // blossom logo color
// $primary: #ad1e4c;

$primary-dark: #f27638;
$link-color: black;
$disabledPrimary: #5aaaff;
// $white:#fff;
