// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

// @import "~react-image-gallery/styles/scss/image-gallery.scss";
// @import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
// @import url("bulma/css/bulma.css");
// @import url("material-design-icons/iconfont/material-icons.css");
